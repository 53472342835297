<template>
  <div class="viewDashBoard" v-cloak>
    <div class="container">
      <div class="white-row">
        <h2 class="global-style-heading">
          Payment Method
          <a
            @click.prevent="refreshNotificationTable"
            href=""
            class="refreshData"
            ><i class="fas fa-redo-alt"></i
          ></a>
        </h2>
        <div
          v-if="
            typeof getActiveUserData.user !== 'undefined' &&
              Object.keys(getActiveUserData.user).length
          "
        >
          <div class="nofication-body not-len">
            <div
              v-if="payment_method_loader"
              class="white-row loader-route notification-loader"
            >
              <div class="lds-sub-ripple">
                <div></div>
                <div></div>
              </div>
            </div>
            <div
              class="wrapper-notifications"
              v-if="
                typeof payment_method_data.data !== 'undefined' &&
                  Object.keys(payment_method_data.data).length
              "
            >
              <div class="not-groups">
                <form v-on:submit.prevent="onSubmit($event)">
                </form>
                <div class="card mt-50 mb-50">
                      <form>
                        <span id="card-header">Saved cards:</span>
                        <div class="row row-1"
                          v-for="(card ,indx) in payment_method_data.data" :key="indx"
                        >
                          <div class="col-2">
                            <div class="custom-control custom-radio">
                              <input @change="updateValue(indx)" :checked="updateCardDefaultValue(card.default)" type="radio" :id="'customRadio_'+card.id" name="radio" class="custom-control-input">
                              <label class="custom-control-label" :for="'customRadio_'+card.id"></label>
                            </div>
                            <img class="img-fluid" :src="'/images/'+card.card_type+'.png'" />
                          </div>
                          <div class="col-7">
                            <p class="card-num">**** **** **** {{ card.last4 }}</p>
                          </div>
                          <div class="col-3 d-flex justify-content-center">
                            <a href="#"
                              v-confirm="{
                                loader: true,
                                ok: dialog =>
                                  deletCard(dialog, {
                                    deletCard: indx,
                                  }),
                                cancel: doNothing,
                                message:
                                  'Are you sure? You want to delete it.'
                              }"
                            >Remove card</a> 
                          </div>
                        </div>
                      </form>
                      <!-- <div class="row row-1">
                        <div class="col-2">
                          <img class="img-fluid" src="https://img.icons8.com/color/48/000000/visa.png" />
                        </div>
                        <div class="col-7">
                          <input type="text" placeholder="**** **** **** 4296"> 
                        </div>
                        <div class="col-3 d-flex justify-content-center"> 
                          <a href="#">Remove card</a> 
                        </div>
                      </div> -->
                      <!-- <form>
                        <span id="card-header">Add new card:</span>
                        <div class="row-1">
                          <div class="row row-2"> <span id="card-inner">Card holder name</span> </div>
                          <div class="row row-2"> <input type="text" placeholder="Bojan Viner"> </div>
                        </div>
                        <div class="row three">
                          <div class="col-7">
                              <div class="row-1">
                                <div class="row row-2"> <span id="card-inner">Card number</span> </div>
                                <div class="row row-2"> <input type="text" placeholder="5134-5264-4"> </div>
                              </div>
                          </div>
                          <div class="col-2"> <input type="text" placeholder="Exp. date"> </div>
                          <div class="col-2"> <input type="text" placeholder="CVV"> </div>
                        </div>
                        <button class="btn d-flex mx-auto"><b>Add card</b></button>
                    </form> -->
                </div>
              </div>
            </div>
            <div v-else class="wrapper-notifications">
              <div class="no-card-added">
                <div class="udlite-text-md app--stored-payment-method--empty-view--3AOLa">You don’t have any saved payment method</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="white-row loader-route">
          <div class="lds-sub-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import norecordfound from "../../components/no-record-found";

// import VueCardFormat from 'vue-credit-card-validation';
 
// Vue.use(VueCardFormat);
// import Avatar from "vue-avatar";
export default {
  components: {
    // VueCardFormat,
    // "no-record-found": norecordfound
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    },
  },
  data() {
    return {
      payment_method_loader: true,
      total_count: 0,
      payment_method_data: {}
    };
  },
  mounted() {
    document.title = this.$router.app._route.meta.pageTitle;
    this.getUserCards();
  },
  methods: {
    updateCardDefaultValue(value) {
      if(value == 'no') {
        return false;
      } else {
        return true;
      }
    },
    async updateValue(get_index){
      var card_id = this.payment_method_data.data[get_index].id;
      const data = this.$store.dispatch("getHttp", {
        url:
          "cards/get-user-cards/update-default-card/"+card_id
      });
      await data.then(response =>{
        if (typeof response != "undefined") {
          // console.log(response);
          this.$store.dispatch("showtostrMessageNotifications", {
            message: response.data.message,
            type: response.data.status
          });
        }
      });

    },
    async deletCard(dialog,card_index) {
      
      const $this = this;

      let ind = card_index.deletCard;
      let cardid = $this.payment_method_data.data[ind].id;
      const data = $this.$store.dispatch("deleteHttp", {
        url: "cards/get-user-cards/delete/" + cardid
      });
      await data.then(response => {
        if (typeof response !== "undefined") {
          if(response.data.status == 'success') {
            this.$store.dispatch("showtostrMessageNotifications", {
              message: response.data.message,
              type: response.data.status
            });
            this.payment_method_data.data.splice(
              ind,
              1
            );
          } else {
            this.$store.dispatch("showtostrMessageNotifications", {
              message: response.data.message,
              type: 'error'
            });
          }
          dialog.close();
        }
      });
    },
    doNothing() {
      console.log("Ignore");
    },
    refreshNotificationTable() {
      this.getUserCards();
    },
    async getUserCards(page = 1, action = null) {
      const $this = this;
      $this.payment_method_loader = true;
      const data = $this.$store.dispatch("getHttp", {
        url:
          "cards/get-user-cards?timezone=" +
          Intl.DateTimeFormat().resolvedOptions().timeZone +
          "&page=" +
          page
      });
      await data.then(function(response) {
        if (typeof response != "undefined") {
          $this.payment_method_data = response.data;
          $this.payment_method_loader = false;
        }
      });
    },
  }
};
</script>
<style lang="scss" scoped>
.nofication-body.not-len {
    position: relative;
}
body .white-row.loader-route {
    height: 150px;
    position: relative;
    top: 50px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: none;
    position: absolute;
    width: 100%;
    z-index: 99;
    opacity: 1;
    top: 0;
    height: 100%;
}
.no-card-added {
    padding: 30px 0 0;
}
.no-card-added .udlite-text-md {
    border: 2px dashed #d1d7dc;
    box-sizing: border-box;
    color: #6a6f73;
    padding: 48px 0;
    font-weight: 400;
    line-height: 1.4;
    font-size: 22px;
    text-align: center;
}

.card img.img-fluid {
    width: 45px;
}
.card {
    margin: auto;
    width: 100%;
    padding: 0 0;
    border: none;
}
.card-num {
    margin-bottom: 0;
    font-weight: bold;
    color: #212529;
    font-size: 21px;
}
.mt-50 {
    margin-top: 50px
}

.mb-50 {
    margin-bottom: 50px
}

@media(max-width:767px) {
    .card {
        width: 90%;
        padding: 1.5rem
    }
}

@media(height:1366px) {
    .card {
        width: 90%;
        padding: 8vh
    }
}

.card-title {
    font-weight: 700;
    font-size: 2.5em
}

.nav {
    display: flex
}

.nav ul {
    list-style-type: none;
    display: flex;
    padding-inline-start: unset;
    margin-bottom: 6vh
}

.nav li {
    padding: 1rem
}

.nav li a {
    color: black;
    text-decoration: none
}

.active {
    border-bottom: 2px solid black;
    font-weight: bold
}

input {
    border: none;
    outline: none;
    font-size: 1rem;
    font-weight: 600;
    color: #000;
    width: 100%;
    min-width: unset;
    background-color: transparent;
    border-color: transparent;
    margin: 0
}

form a {
    color: grey;
    text-decoration: none;
    font-size: 0.87rem;
    font-weight: bold
}

form a:hover {
    color: grey;
    text-decoration: none
}

form .row {
    margin: 0;
    overflow: hidden
}

form .row-1 {
    border: 1px solid rgba(0, 0, 0, 0.137);
    padding: 0.5rem;
    outline: none;
    width: 100%;
    min-width: unset;
    border-radius: 5px;
    background-color: rgba(221, 228, 236, 0.301);
    border-color: rgba(221, 228, 236, 0.459);
    margin: 2vh 0;
    overflow: hidden
}

form .row-2 {
    border: none;
    outline: none;
    background-color: transparent;
    margin: 0;
    padding: 0 0.8rem
}

form .row .row-2 {
    border: none;
    outline: none;
    background-color: transparent;
    margin: 0;
    padding: 0 0.8rem
}

form .row .col-2,
.col-7,
.col-3 {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 0 1vh
}

form .row .col-2 {
    padding-right: 0
}

#card-header {
    font-weight: bold;
    font-size: 0.9rem
}

#card-inner {
    font-size: 0.7rem;
    color: gray
}

.three .col-7 {
    padding-left: 0
}

.three {
    overflow: hidden;
    justify-content: space-between
}

.three .col-2 {
    border: 1px solid rgba(0, 0, 0, 0.137);
    padding: 0.5rem;
    outline: none;
    width: 100%;
    min-width: unset;
    border-radius: 5px;
    background-color: rgba(221, 228, 236, 0.301);
    border-color: rgba(221, 228, 236, 0.459);
    margin: 2vh 0;
    width: fit-content;
    overflow: hidden
}

.three .col-2 input {
    font-size: 0.7rem;
    margin-left: 1vh
}

.btn {
    width: 100%;
    background-color: rgb(65, 202, 127);
    border-color: rgb(65, 202, 127);
    color: white;
    justify-content: center;
    padding: 2vh 0;
    margin-top: 3vh
}

.btn:focus {
    box-shadow: none;
    outline: none;
    box-shadow: none;
    color: white;
    -webkit-box-shadow: none;
    -webkit-user-select: none;
    transition: none
}

.btn:hover {
    color: white
}

input:focus::-webkit-input-placeholder {
    color: transparent
}

input:focus:-moz-placeholder {
    color: transparent
}

input:focus::-moz-placeholder {
    color: transparent
}

input:focus:-ms-input-placeholder {
    color: transparent
}
</style>
